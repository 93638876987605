import Layout from "@/layout";
import * as React from "react";
import { About } from "@/components";
import SEO from "@/components/seo";
import { useLocation } from '@reach/router';

const Ab = () => {
  const {host} = useLocation();
  const canonical = `${host}/about`;
  return (
    <Layout>
      <SEO title="About" canonical={canonical}/>
      <About />
    </Layout>
  );
};

export default Ab;
